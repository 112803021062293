import classNames from 'classnames';
import {
  FieldCheckbox,
  FieldCheckboxGroup,
  FieldLocationAutocompleteInput,
  FieldRadioButton,
  FieldSelect,
  FieldSingleDatePicker,
  FieldTextInput,
  Heading
} from '../../../components';
import { getServiceDetails, getServices } from '../../../config/requests';
import { required } from '../../../util/validators';
import css from './EditListingDetailsPanel/EditListingDetailsForm.module.css';

const RequestForm = props => {
  const { intl, formId, formValues, submitInProgress, hasSections = false, currentSection, isListing = false } = props;
  const { service } = formValues || {};
  const SERVICES = getServices(intl) || [];
  const DETAILS = service ? getServiceDetails(service, intl) : {};
  console.log("formValues", formValues);
  return <>
    {!isListing && (!hasSections || !currentSection) ? <FieldSelect
      name="service"
      id={formId ? `${formId}.service` : 'service'}
      label="¿Qué tipo de servicio requieres?"
      className={css.input}
      validate={required('Debes seleccionar un tipo de servicio')}
    >
      <option hidden >Tipo de servicio</option>
      {SERVICES.map(service => (
        <option value={service.option} key={service.option} 
          selected={service.option == formValues.service}
        >{service.label}</option>
        ))}
    </FieldSelect> : null}

    {isListing && service ? <div className={css.serviceName}>{service}</div> : null}

    {(DETAILS?.sections || []).map((section, sKey) => {
      let questions = (section.questions || []).filter(q => !(isListing && q.hideOnView));

      return <div key={sKey} className={classNames({[css.hiddenSection]: hasSections && currentSection != sKey})}>
        <Heading as="h4" rootClassName={css.subtitleHeading}>
          {section.subtitle}
        </Heading>

        {questions.map((question, qKey) => {
          let Component = null;
          let iconProp = {};
          let optionsProp = {};
          let inputDisabled = submitInProgress;

          switch (question.type) {
            case 'text':
            case 'number':
            case 'email':
            case 'textarea':
            case 'date':
              Component = FieldTextInput;
              break;
            case 'select':
              Component = FieldSelect;
              break;
            case 'checkbox':
              Component = FieldCheckbox;
              break;
            case 'checkboxGroup':
              Component = FieldCheckboxGroup;
              optionsProp = { options: question.options, disabled: inputDisabled };
              break;
            case 'datepicker':
              Component = FieldSingleDatePicker;
              break;
            case 'location':
              Component = FieldLocationAutocompleteInput;
              iconProp = { iconClassName: css.hiddenIcon }
            break;
          }

          if (question.visibleParam !== undefined && question.visible !== undefined && typeof question.visible === 'function') {
            if (!question.visible(formValues[question.visibleParam])) {
              return null;
            }
          }

          if (isListing) {
            let isVertical = ['textarea', 'checkboxGroup', 'location'].includes(question.type);
            console.log("formValues", formValues);
            console.log("question", question);
            let value = formValues[question.name];
            if (question.type == 'location') {
              value = value?.selectedPlace?.address || '';
            }
            if (['select', 'radio'].includes(question.type)) {
              value = (question.options || []).find(o => o?.value == value)?.label || value;
            }
            if (['checkboxGroup'].includes(question.type)) {
              console.log(question.options || [])
              console.log(value)
              let valueOptions = (question.options || []).filter(o => value.includes(o?.key || o?.value)) || [];
              console.log("valueOptions", valueOptions);
              if (valueOptions.length > 0) {
                value = <ul>{valueOptions.map((vOpt, vKey) => {
                  return <li key={vKey} className={css.selectedLi}>
                      <span>
                        <svg width="9" height="9" xmlns="http://www.w3.org/2000/svg">
                          <path
                            className={css.marketplaceFill}
                            d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
                            fillRule="evenodd"
                          />
                        </svg>
                      </span>
                      <span>{vOpt.label}</span>
                    </li>;
                })}</ul>;
              } else {
                value = null;
              }
            }
            console.log("value", value);
            if (!value) {
              return null;
            }
            return <div key={`${qKey}_${question.name}`} className={classNames(css.listingFormValue, {[css.verticalListingFormValue]: isVertical})}>
                  {console.log("question", question)}
                <div>{question.label}</div>
                <div>{value}</div>
              </div>;
          }

          let validate = question.validate ? question.validate : null;
          let placeholderProp = !['select'].includes(question.type) ? { placeholder: question.placeholder || question.label} : {};

          if (question.type == 'radio') {
            return <div key={`${qKey}_${question.name}`} className={css.radioGroup}>
              <label>{question.label}</label>
              {question.options.map(opt => {
                return <FieldRadioButton
                  key={`${qKey}_${question.name}_${opt.value}`}
                  id={`${qKey}_${question.name}_${opt.value}`}
                  name={question.name}
                  label={opt.label}
                  value={opt.value}
                  showAsRequired={true}
                  validate={validate}
                  disabled={inputDisabled}
                  help={opt.description}
                />;
              })}
            </div>;
          }

          if (question.type == 'paragraph') {
            return <p key={`${qKey}_paragraph}`}>{question.label}</p>
          }

          if (!Component) {
            return null;
          }

          return (
            <Component
              key={`${qKey}_${question.name}`}
              id={question.name}
              name={question.name}
              className={css.input}
              validate={validate}
              label={question.label}
              disabled={inputDisabled}
              readOnly={inputDisabled}
              {...placeholderProp}
              {...question.otherProps}
              {...iconProp}
              {...optionsProp}
            >
              {question.type == 'select' && question?.options && Array.isArray(question.options) ? <>
                <option disabled key={`${question.name}_disabled`} value="">{question.label}</option>
                {question.options.map(opt => {
                  return (
                    <option key={`${question.name}_${opt.value}`} value={opt.value}>
                      {opt.label}
                    </option>
                  );
                })} </> : null}
            </Component>);
        })}
      </div>
    })}
  </>
};

export default RequestForm;
