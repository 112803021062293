import React from 'react';
import { string, func, bool, oneOfType } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, Avatar, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    sendQueryParams,
    isSuggestion,
    request
  } = props;
  const classes = classNames(rootClassName || css.root, className, {[css.suggestionCard]: isSuggestion});
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  let { listingFields = [] } = config?.listing || {};
  let cloudFieldOptions = (listingFields.find(f => f.key == 'cloudExperience')?.enumOptions || []).filter(opt => {
    return (publicData?.cloudExperience || []).includes(opt.option);
  }).map(opt => opt.label);

  function toQueryString(obj, prefix = '') {
    return Object.keys(obj).map(key => {
        const value = obj[key];
        const queryKey = prefix ? `${prefix}[${key}]` : key;

        if (typeof value === 'object' && !Array.isArray(value)) {
            return toQueryString(value, queryKey);
        } else if (Array.isArray(value)) {
            return value.map((item, index) => toQueryString({ [index]: item }, queryKey)).join('&');
        } else {
            return `${encodeURIComponent(queryKey)}=${encodeURIComponent(value)}`;
        }
    }).join('&');
}

  const authorAnonimity = author.attributes.profile.publicData?.anonimizeData === undefined || author.attributes.profile.publicData?.anonimizeData === true ? true : false;
  
  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }} to={{ search: request ? '?'+ toQueryString(request) : '' }}>
      <Avatar avatarClass={css.avatar} className={css.avatarContainer} user={author} disableProfileLink isAnonimized={authorAnonimity} />
      <div className={css.info}>
        <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
           {isSuggestion ?  <span className={css.titleSuffix}>
              |
            </span> : null}
              {isSuggestion && cloudFieldOptions.length ? <div className={css.services}>{cloudFieldOptions.join(', ')}</div> : null}
          </div>
          {(showAuthorInfo || !authorAnonimity) ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
          {isSuggestion ? <div className={css.listingDescription}>
            {currentListing?.attributes?.description || ''}
          </div> : null}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
  isSuggestion: false,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  showAuthorInfo: bool,
  isSuggestion: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
